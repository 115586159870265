export const EventVar = {
    meeting: {
        date: "October 3rd",
        time: "7 PM",
        location: "Bill Miller's"
    },
    tournament:
    {
      date: "October 26-27th",
      location: "O.H. Ivie - Kennedy Boat Ramp"
    }
  }  