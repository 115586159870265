import { AOTY, BigBass, Winner } from "./interfaces";

export const AOTYData: AOTY[] = [
    { place: "Anglers of the Year", name: "Chuck Kersch / John Trevino" },
    { place: "2nd", name: "The Veras" },
    { place: "3rd", name: "Jacob Saenz / Shawn Warren" },
]

export const AOTYBBData: BigBass = {
    weight: 10.73,
    location: "O.H. Ivie",
    date: "4/16/2023",
    angler: "Henry Vera Jr",
    image_url: "https://storage.googleapis.com/mlbc-assets/static/henry-jr-bb-oh-ivie-4-16-2023.JPG"
}

export const WinnersData: Winner[] = [
    { place: "1st", name: "The Veras" },
    { place: "2nd", name: "Don Rogers" },
    { place: "3rd", name: "John/Chuck" },
]

export const WinnerBBData: BigBass = {
    weight: 6.55,
    location: "Choke Canyon",
    date: "7/15/2023",
    angler: "Henry Vera",
    image_url: "https://storage.googleapis.com/mlbc-assets/static/henry-bb-choke-7-15-2023.jpg"
}